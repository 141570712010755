.x-slick-arrow {
    width: auto;
    height: auto;
    font-size: 2rem;
    color: $gray-500;

    &::before {
        display: none;
    }

    &.slick-disabled {
      opacity: .25;
    }

    &:focus,
    &:hover {
        color: darken($gray-200, 10%);
    }

    &.-next {
        right: -13px;
    }

    &.-prev {
        left: -13px;
    }
}
