.x-hr-employee-commission-container {
    table {
        thead {
            th {
                color: $white;
                background-color: $primary;
                &:first-child {
                    border-top-left-radius: .5rem;
                }
                &:last-child {
                    border-top-right-radius: .5rem;
                }
            }
        }
        tbody {
            td {
                vertical-align: middle;
            }
        }
    }
}
