.x-form-square-box {
    position: relative;

    a {
        display: block;
        text-align: center;
    }

    .-img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 200px;
        background: #cad0ed;
        .fas {
            font-size: 2.5rem;
        }
    }

    .-create-at {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: $font-size-smaller;
        color: $text-muted;
    }

    .-text {
        margin-top: .5rem;
        font-size: $font-size-small;
    }
}
