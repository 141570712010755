.treatment-history-modal-onepage {
  font-size: 10px;

  p {
    font-size: 11px;
  }

  .customer-info-card-profile {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  }

  .customer-info-card-a {
    all: unset;
    cursor: pointer;
  }

  .customer-info-card-xl {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f5f7f8;
    margin-right: 5px;
    .section-container {
      display: flex;
      flex-flow: row nowrap;

      a {
        color: $theme-font-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .title {
      color: $primary;
      font-weight: bold;
      margin-right: 5px;
      white-space: nowrap;
    }

    .red-text {
      color: #fd6460;
      .title {
        color: #fd6460;
      }
    }

    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }
  }

  .treatment-plan-box {
    border: 1px solid $primary;
  }

  .treatment-package-history-onepage {
    .package-detail {
      font-size: $onepage-font-size-s-a3 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a3 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a3 !important;
      }
    }

    input {
      font-size: $onepage-font-size-s-a3 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a3 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a3 !important;
      }
      height: 25px !important;
      background-color: #ffffff !important;
    }

    button {
      float: right;
      margin-top: 10px !important;
      background-color: #5ad392;
      border-color: #5ad392;
      font-size: 8px;
    }
  }

  .tab-top-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-container {
    border-radius: 3px;
    width: 20px !important;
    height: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  .treatment-note {
    font-size: 11px !important;
    padding: 7px;

    .form-group {
      margin: 0;
    }

    .form-label {
      font-weight: bold;
      margin-bottom: 0;
    }

    .form-control {
      padding: 5px 20px 5px 5px !important;
      font-size: 11px !important;
    }

    .treatment-tryping-note-textarea {
      background-color: #ffffff;

      &:disabled {
        background-color: #e9ecef;
      }

      font-size: $onepage-font-size-s-a2 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a2 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a2 !important;
      }

      &.refer-textarea {
        height: 90px !important;
      }

      &.nextvisit-textarea {
        height: 90px !important;
      }
    }
  }

  .payment-list-table {
    .table-responsive {
      min-height: 120px !important;
      height: 120px !important;

      th,
      td {
        padding: 3px;
        font-size: $onepage-font-size-s-a2 !important;

        @media (min-width: 1920px) {
          font-size: $onepage-font-size-m-a2 !important;
        }

        @media (min-width: 2560px) {
          font-size: $onepage-font-size-l-a2 !important;
        }
      }
    }
  }

  // .treatment-item-history-sm {
  //     th,
  //     td {
  //         font-size: 9px !important;
  //         padding-top: 3px !important;
  //         padding-bottom: 3px !important;
  //     }
  // }

  .customer-note-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 90px;
    margin-top: 0px !important;

    .note {
      font-family: "Mitr" !important;
    }
  }

  .react-select-multiple-container,
  .react-select-multiple__control,
  .react-select-multiple__indicators,
  .react-selec-multiplet__value-container {
    // height: 25px;
    // min-height: 25px !important;
  }

  .react-select-multiple__dropdown-indicator,
  .react-select-multiple__clear-indicator {
    padding: 0 !important;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .typing-form-cdt-label {
    width: 110px;
  }

  .typing-form-cdt-value {
    color: $gray-600;
  }
}

.ortho-chart-footer {
  .ipr-btn {
    background-color: #ebebec;
    border: 0;
    color: #000000;
    padding: 2px 5px;
    border-radius: 3px;
    height: 24px;

    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }

    &.zoom-view {
      height: 30px;
      font-size: 12px;
    }

    &.active {
      color: $white;
      background: $primary;
    }
  }

  .ortho-appliance-input {
    height: 25px;
    width: 150px;
    padding: 2px !important;
    padding-right: 25px !important;

    font-size: $onepage-font-size-s-a5 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a5 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a5 !important;
    }

    &.zoom-view {
      width: 200px;
      font-size: 10px;
      height: 30px;
    }
  }

  .ortho-text-shortcut-btn {
    position: absolute;
    right: 0px;
    width: 20px;
    height: 20px;
    text-align: right;
    padding-right: 5px;
    font-size: 12px;
    top: 2px;
    color: $gray-700;
  }
}

.normal-form {
  .ortho-text-shortcut-btn {
    position: absolute;
    right: 0px;
    width: 20px;
    height: 20px;
    text-align: right;
    padding-right: 5px;
    font-size: 12px;
    top: 2px;
    color: $gray-700;
  }
}

.ipr-squre-btn {
  width: 5px;
  height: 5px;
  background-color: #eb6e66;
  transform: rotate(45deg);

  &.zoom-view {
    width: 10px;
    height: 10px;
  }
}

.ipr-squre-btn-disable {
  width: 5px;
  height: 0.5px;
  background-color: transparent;

  &.zoom-view {
    width: 10px;
    height: 0.5px;
  }
}

.spacing-active-btn {
  width: 5px;
  height: 4px;
  border: 1px solid $primary;
  border-top: 0;

  &.zoom-view {
    width: 10px;
    height: 8px;
  }
}

.ipr-active-btn {
  width: 5px;
  height: 15px;
  display: flex;
  justify-content: center;

  &.zoom-view {
    width: 10px;
    height: 22px;
  }
}

.writing-onepage {
  font-size: 9px !important;

  .-btn {
    width: 20px !important;
    height: 20px !important;
    border-radius: 3px !important;
    margin-left: 3px;
    padding: 1px;
    font-size: 10px !important;
    color: $body-color;
    background: #eff0f2;
    border-color: #eff0f2;
    &.-active {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
    &:not(.-active) {
      &:hover {
        background: rgba($primary, 0.25);
        border-color: rgba($primary, 0.25);
      }
    }
  }

  .-title-info-wrapper {
    font-size: 12px !important;
    color: #b8b8b8;
  }

  .icon-container {
    border-radius: 3px;
    width: 20px !important;
    height: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
}

.x-treatment-history-tab,
.x-treatment-history-tab-package,
.x-treatment-lab-tab,
.x-treatment-plan-sm {
  font-size: $onepage-font-size-s-a3 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a3 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a3 !important;
  }

  .form-control {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }
  }

  input {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }
  }
}

.zoom-teeth-btn-group {
  position: absolute;
  right: 20px;
  top: 5px;
}

.x-treatment-history-tab,
.x-treatment-history-tab-package,
.x-treatment-history-tab-package,
.x-treatment-plan-sm {
  .form-group {
    margin-bottom: 5px !important;
  }

  .form-label {
    margin-bottom: 0 !important;
  }

  input {
    height: 30px !important;
  }

  .react-select-container,
  .react-select__control,
  .react-select__indicators,
  .react-select__value-container {
    height: 30px;
    min-height: 30px !important;

    input {
      height: inherit !important;
    }
  }

  button {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }
  }
}

.wire-tire-sm {
  .form-group {
    margin-top: 2px !important;
  }

  .react-select-container,
  .react-select__control,
  .react-select__indicators,
  .react-select__value-container {
    height: 25px !important;
    min-height: 25px !important;
  }

  // input {
  //     height: 25px !important;
  // }
}

.wire-size-select {
  .react-select__menu {
    z-index: 9999;
  }
}

.x-treatment-history-tab,
.x-treatment-history-tab-package {
  div:has(.react-select__menu) {
    z-index: 9999;
  }
}

.treatment-ortho-image-view {
  .slick-slider .slick-prev {
    left: 0 !important;
  }

  .slick-slider .slick-next {
    right: 10px !important;
  }
}

.open-camera {
  .close {
    color: #000000 !important;
  }
}

.modal-tooltip-custom {
  z-index: 999999 !important;
}

.treatment-refer-onepage {
  font-size: $onepage-font-size-s-a5 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a5 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a5 !important;
  }

  .form-label {
    font-weight: 500 !important;
  }

  .next-visit-tag {
    font-size: 7px !important;
    padding: 2px !important;
  }
}

.treatment-refer-onepage-horizontal {
  font-size: $onepage-font-size-s-a5 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a5 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a5 !important;
  }
}

.plan-radio-sm {
  font-size: 12px !important;
}

.plan-print-btn {
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.teeth-select-form-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  &.-hide {
    opacity: 0;
    visibility: hidden;
  }
  &.-show {
    opacity: 1;
    visibility: visible;
  }

  &.-plan {
    right: 50px !important;
  }
}

.treatment-history-modal-onepage,
.treatment-record-modal-custom-width,
.ortho-treatment-plan-form,
.aligner-form,
.x-treatment-history-tab,
.x-treatment-history-tab-package,
.x-treatment-lab-tab,
.x-treatment-plan-sm {
  input[type="checkbox"]:focus {
    box-shadow: 0 0 5px blue !important;
  }

  input[type="radio"]:focus {
    box-shadow: 0 0 5px blue !important;
  }

  .custom-radio {
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 5px blue !important;
    }
  }
}

.ortho-plan-tab-container {
  .nav-link {
    color: #495057;

    &.active {
      color: $primary !important;
    }
  }
}

#extra-intra-oral-tab-menu-tab-extraoral,
#extra-intra-oral-tab-menu-tab-intraoral {
  &.nav-link {
    color: #495057;

    &.active {
      color: $primary !important;
    }
  }
}

.x-sketch-drawer-wrapper {
  &.sm-layout {
    button {
      &:focus {
        box-shadow: 0 0 1px 2px $blue !important;
      }
    }
  }
}

.treatment-history-show-warning {
  position: absolute;
  top: 2px;
  right: 2px;
}

.cdt-list-modal {
  max-height: 500px;

  .table-responsive {
    min-height: 0;
  }

  .treatment-item-history-sm {
    th,
    td {
      font-size: 16px !important;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }
}

.onepage-heading-a1,
.onepage-input-font-a1 {
  font-size: $onepage-font-size-s-a1 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a1 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a1 !important;
  }
}

.onepage-heading-a2,
.onepage-input-font-a2 {
  font-size: $onepage-font-size-s-a2 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a2 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a2 !important;
  }
}

.onepage-heading-a3,
.onepage-body-a3 {
  font-size: $onepage-font-size-s-a3 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a3 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a3 !important;
  }
}

.onepage-heading-a4,
.onepage-body-a4 {
  font-size: $onepage-font-size-s-a4 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a4 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a4 !important;
  }
}

.onepage-heading-a5,
.onepage-body-a5 {
  font-size: $onepage-font-size-s-a5 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a5 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a5 !important;
  }
}
