.x-crm-treatment-history {
  .-file-status {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 2px dashed rgba(black, 0.5);
  }

  .-image-list {
    display: flex;
    flex-wrap: wrap;
  }

  .-image-btn {
    width: 20%;
    background: transparent;
    border: none;
    @include media-breakpoint-down(lg) {
      width: 25%;
    }
    @include media-breakpoint-down(md) {
      width: calc(100% / 3);
    }
    &:not(:disabled):not(.disabled) {
      &:active {
        background: rgba($primary, 0.1);
      }
    }
    &:hover {
      background: rgba($primary, 0.2);
    }
    &.-active {
      background: rgba($primary, 0.2);
    }
  }
}

.patient-label-container {
  background-color: $gray-200;
  .patient-row {
    &__label {
      width: 80px;
      display: inline-block;
    }
  }
}

.patient-label-container-sm {
  background-color: $gray-200;
  font-size: 9px;
  width: 100%;
  height: 80px;
  .patient-row {
    &__label {
      width: 50px;
      display: inline-block;
    }
  }
}

.send-treatment-container-sm {
  font-size: 9px;
  height: 65px;

  p {
    font-size: 11px !important;
    font-weight: bold !important;
    margin-bottom: 5px;
  }
}

:root {
  --treatment-plan-height: 200px;
}

.treatment-plan-scroll {
  border: 1px solid $primary;
  .table-responsive {
    height: var(--treatment-plan-height) !important;
    width: 100%;
    min-height: 0px !important;
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }

    .table thead th {
      font-size: $onepage-font-size-s-a5 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a5 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a5 !important;
      }
    }

    .table td,
    .table th {
      padding: 2px 5px !important;
    }
  }
}

.treatment-ortho-plan-scroll {
  .table-responsive {
    height: 100px !important;
    // height: calc((var(--treatment-plan-height) / 2)) !important;
  }

  p {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }
  }

  .plan-message {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }

    height: 80px;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: scroll;
  }

  .custom-link {
    text-decoration: underline;
    font-weight: 400;
  }

  th {
    font-size: $onepage-font-size-s-a5 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a5 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a5 !important;
    }
  }

  td {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }
  }
}

.treatment-history-scroll {
  height: 620px;
  width: 100%;

  &.treatment-history-scroll-crm {
    height: 550px;
  }

  .history-card-header {
    background-color: $gray-200;

    .h-title {
      text-align: center;
      font-weight: bold;
      padding: 2px 0;
      border: 0.5px solid $gray-400;

      font-size: $onepage-font-size-s-a4 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a4 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a4 !important;
      }
    }
  }

  .history-card-row {
    height: 490px;
    overflow: scroll;
  }

  .history-card-body {
    // font-size: 7px !important;
    font-size: $onepage-font-size-s-a4 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a4 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a4 !important;
    }

    background-color: $gray-400;

    &.x-onepage {
      background-color: $gray-200;
    }

    .h-info {
      padding: 2px 1px;
      border: 0.5px solid $gray-600;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .form-state-btn-container {
      height: 18px;
      width: 14px;
      margin-right: 1px;
    }

    .form-state-btn {
      border: 1px solid;
      // padding: 1px 2px;
      border-radius: 3px;
      height: 18px;
      width: 14px;
      text-align: center;
      background-color: #ffffff;
      cursor: pointer;

      &.info {
        color: $blue;
        border-color: $blue;
      }

      &.primary {
        color: $primary;
        border-color: $primary;
      }

      &.secondary {
        color: $secondary;
        border-color: $secondary;
      }
    }
  }

  .t-typing-note {
    .border {
      border: 0.5px solid $gray-600 !important;
    }

    .typing-title {
      font-weight: bold;
    }
    // font-size: 7px !important;

    .typing-detail {
      word-wrap: break-word;
      white-space: pre-line;
    }
  }

  .table-responsive {
    height: 420px !important;
    width: 100%;
    min-height: 0px !important;
    font-size: 9px !important;

    .table thead th {
      font-size: 9px !important;
    }

    .table td,
    .table th {
      padding: 10px 5px !important;
    }
  }

  .pagination {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }

    margin-bottom: 0 !important;

    .page-link {
      width: 24px !important;
      padding: 3px 6px !important;
      margin: 0px 2px !important;
      text-align: center !important;
    }
  }

  .last-treatment-history-log-table {
    td {
      white-space: normal !important;
    }
  }

  .custom-link {
    text-decoration: underline !important;
    cursor: pointer;
  }

  .treatment-disabled-link {
    text-decoration: underline !important;
    color: $gray-400 !important;
  }

  &.treatment-history-crm {
    .history-card-header {
      .h-title {
        font-size: 11px;
        padding: 5px 0;
      }
    }

    .history-card-body {
      font-size: 10px !important;

      .form-state-btn {
        font-size: 7px !important;
      }
    }

    .t-typing-note {
      font-size: 10px !important;

      .typing-detail {
        word-wrap: break-word;
      }
    }

    .pagination {
      font-size: 10px !important;
    }
  }
}

.treatment-package-history {
  width: 100%;
  border: 1px solid #5ad392;
  background-color: #ebfdf3;

  .p-title {
    color: #5ad392;
  }

  .package-detail {
    font-size: 12px;
  }

  .form-group {
    margin: 0 !important;
  }

  input {
    font-size: 12px !important;
    height: 30px;
    background-color: #ffffff !important;
  }

  label {
    margin-bottom: 0;
    font-weight: bold;
  }

  button {
    float: right;
    margin-top: 15px;
    background-color: #5ad392;
    border-color: #5ad392;
  }
}

#treatment-history-tab-sm-tab-writing,
#treatment-history-tab-sm-tab-typing,
#treatment-history-tab-sm-tab-lab,
#typing-record-sub-tab-tab-gp,
#typing-record-sub-tab-tab-ortho,
#treatment-history-tab-menu-sm-tab-treatment,
#treatment-history-tab-menu-sm-tab-package,
#treatment-history-tab-menu-sm-tab-goods {
  padding-top: 0px;
  padding-bottom: 2px;

  font-size: $onepage-font-size-s-a2 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a2 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a2 !important;
  }
}

// #treatment-history-tab-menu-sm-tabpane-treatment,
// #treatment-history-tab-menu-sm-tabpane-package,
// #treatment-history-tab-menu-sm-tabpane-goods {
//     height: 160px;
//     overflow: auto;
// }

.x-treatment-history-tab-package {
  .-btn-package-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    padding: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $primary;
    border-radius: 50%;
    background: transparent;
    color: $primary;
    &:hover {
      color: $white;
      background: $primary;
    }
  }
}

.treatment-note {
  font-size: 11px !important;
  padding: 7px;

  .form-group {
    margin: 0;
  }

  .form-label {
    font-weight: bold;
  }

  .form-control {
    padding-right: 30px !important;
  }

  hr {
    border-top: 1px solid #e3eaef;
  }

  .align-items-center {
    align-items: center;
  }
}

.treatment-note-container {
  background-color: $gray-200;
  padding: 10px;
  margin-top: 10px;
  height: 100px;
}

.edit-text-shortcut-btn {
  position: absolute;
  right: 0px;
  top: 30px;
  width: 25px;
  height: 25px;
  text-align: right;
  padding-right: 5px;

  &.is_sm {
    top: 20px;
  }
}

.refer-preliminary-suggest {
  flex: 1;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid $gray-300;
  border-radius: 5px;
  padding: 5px;
  font-size: 8px;
  margin-top: 10px;
}

.input-shortcut-form-refer-preliminary-suggest {
  textarea {
    font-size: 12px;
    padding: 30px 5px 5px 5px !important;
    height: 200px;
  }

  .border-primary {
    border: 1px solid $primary !important;
  }
}

.show-teeth-table-btn {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  i {
    color: $gray-500;
    font-size: 20px;
  }
}

.note-shortcut-table {
  padding-top: 5px;
  .noborder {
    td {
      border-top: none;
    }
  }

  button {
    border: none;
    color: $gray-600;
  }

  .plan-message-text {
    white-space: pre-line;
  }
}

.treatment-item-history-sm {
  th,
  td {
    font-size: 12px;
  }
}

.zoom-teeth-btn {
  // float: right;
  width: 20px;
  height: 20px;
  // text-align: right;
  color: $gray-500;
}

.select-teeth-zoom-modal {
  .close {
    color: $gray-600 !important;
  }
}

.treatment-history-image-view {
  font-size: 10px;

  .image-group-header {
    background-color: $gray-300;
    height: 35px;
  }

  .image-group-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    color: #ffffff;
    right: 20px;
    font-size: 25px;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .slick-slide {
    padding: 0 !important;
  }

  .slick-slider .slick-prev {
    left: 10px !important;
  }

  .slick-slider .slick-next {
    right: 10px !important;
  }

  .slick-slider .slick-prev:before,
  .slick-next:before {
    color: $gray-600 !important;
  }

  .treatment-image-crop {
    height: 120px;
  }
}

.treatment-image-album-modal {
  .close {
    color: $gray-600 !important;
  }

  font-size: 11px;
}

.ortho-treatment-plan {
  .close {
    color: $gray-600 !important;
  }
}

.ortho-treatment-plan-form {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;

  // .patient-label-container-md {
  //     background-color: $gray-200;
  //     font-size: 11px;
  //     width: 100%;
  //     .patient-row {
  //         &__label {
  //             width: 65px;
  //             display: inline-block;
  //         }
  //     }
  // }

  .ortho-plan-tab-container {
    margin-bottom: 5px !important;
  }

  .nav-tabs {
    a {
      font-size: $onepage-font-size-s-a3 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a3 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a3 !important;
      }

      padding: 0px 5px;
      height: 20px;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .t-input-sm {
    font-size: 12px !important;
    height: 30px;
  }

  .react-select-container,
  .react-select__control,
  .react-select__indicators,
  .react-select__value-container {
    height: 30px;
    min-height: 30px !important;
  }

  .extraoral-exam,
  .smile-analysis,
  .function,
  .intraoral-radiographic {
    .react-select__single-value,
    .react-select__placeholder {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .ortho-plan-sm-select__value-container {
    height: 30px;
  }

  .f-title {
    font-weight: bold;
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  .f-title-regular {
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  .w-50-p {
    width: 50%;
  }

  .w-30-p {
    width: 30%;
  }

  .w-70-p {
    width: 70%;
  }

  .function {
    .custom-control-label {
      padding-top: 5px;
    }
  }

  .select-w-100 {
    max-width: 100px !important;

    .react-select__single-value,
    .react-select__placeholder {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .input-shortcut-form {
    textarea {
      font-size: 12px;
      padding: 5px !important;
      height: 180px;
    }

    &.short-height {
      textarea {
        height: 150px !important;
      }
    }

    .border-primary {
      border: 1px solid $primary !important;
    }
  }

  .custom-link {
    text-decoration: underline !important;
    cursor: pointer;
    color: #868e96 !important;
  }

  .t-input-md {
    font-size: 12px;
    padding: 5px !important;
    height: 40px;
  }

  .btn-sign {
    font-size: 12px;
    width: 180px;
    height: 30px;
    padding: 0;
    color: #000000;
    background-color: #e8e8e8;
    border-color: #e3e3e3;
    &:hover {
      background-color: darken(#e8e8e8, 7.5%);
      border-color: darken(#e3e3e3, 7.5%);
    }

    &:focus {
      box-shadow: 0 0 1px 2px $blue !important;
    }
  }

  .btn-save-primary {
    font-size: 12px;
    width: 120px;
    border-radius: 5px;
    float: right;
    margin-top: 5px;
    margin-right: 10px;

    &:focus {
      box-shadow: 0 0 1px 2px $blue !important;
    }
  }

  .ortho-plan-note {
    padding: 2px 5px !important;
  }

  .hx-select-view {
    height: 30px;
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 5px;
    color: #495057;

    &:focus {
      box-shadow: 0 0 1px 2px $blue !important;
    }
  }

  .treatment-history-image-view {
    .image-group-header {
      height: 25px !important;
    }

    .image-group-btn {
      width: 15px !important;
      height: 15px !important;
      top: 3px !important;
      font-size: 15px !important;
    }

    .treatment-image-crop {
      height: 80px !important;
    }

    .h-image-slide {
      .slick-slider {
        margin-bottom: 5px !important;
      }
    }
  }
}

.hx-select-modal {
  .custom-link {
    font-size: 12px;
    text-decoration: underline !important;
    cursor: pointer;
    color: #868e96 !important;
  }

  .hx-select-note {
    font-size: 12px;
    padding: 5px !important;
    height: 40px;
  }
}

.refer-extraction-teeth-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-600;
  width: 14px;
  height: 14px;
  background-color: $gray-200;
  border-radius: 7px;
  margin: 1px;
  font-size: 12px;

  &.active {
    color: $white !important;
    background-color: $primary !important;
  }
}

.refer-extraction-teeth-h-line {
  width: 1px;
  height: 20px;
  background-color: $gray-700;
  margin-left: 1px;
  margin-right: 1px;
}

.refer-extraction-teeth-v-line {
  width: 100%;
  height: 1px;
  background-color: $gray-700;
}

.treatment-refer-title {
  // font-size: 11px !important;
  font-size: $onepage-font-size-s-a3 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a3 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a3 !important;
  }

  font-weight: bold;
  margin-bottom: 0;
}

.treatment-refer-checkbox-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form-label {
    font-weight: 500;
  }

  .other-input {
    padding: 5px !important;
    height: 30px;
    width: 70px;
    margin-left: 10px;
    font-size: 11px;

    &.sm {
      height: 25px !important;
      font-size: 8px !important;
    }
  }
}

.ortho-treatment-tab-selection-tooth {
  .tooth-selection {
    // width: 16px;
    // height: 24px;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    // &.zoom-view {
    //     width: 30px;
    //     height: 45px;
    // }

    &.active {
      border-bottom: 1px solid $gray-300;
    }

    &.tire-selected {
      -webkit-filter: drop-shadow(1px 1px 1px #005eff);
      filter: drop-shadow(1px 1px 1px #005eff);
    }

    &:hover {
      -webkit-filter: drop-shadow(1px 1px 1px #222);
      filter: drop-shadow(1px 1px 1px #222);
    }
  }

  .wire-tire-path-render {
    &.active {
      -webkit-filter: drop-shadow(1px 1px 1px #222);
      filter: drop-shadow(1px 1px 1px #222);
    }
  }

  .teeth-h-line {
    width: 1px;
    height: 35px;
    background-color: $gray-300;
    // margin-left: 1px;
    // margin-right: 1px;

    &.zoom-view {
      height: 60px;
    }
  }

  .teeth-v-line {
    width: 100%;
    height: 1px;
    background-color: $gray-300;
  }

  .state-info {
    font-size: 9px;
    flex: 1;

    &.zoom-view {
      font-size: 14px;
    }
  }

  .chart-action {
    a {
      font-size: $onepage-font-size-s-a3 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a3 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a3 !important;
      }

      color: $gray-500 !important;
    }

    &.zoom-view {
      a {
        font-size: 14px;
      }
    }
  }

  .zone-selection {
    margin-top: 5px;

    .btn {
      color: #a8a6ac;
      background: #ebebec;
      border-color: #ebebec;
      color: #000000;
      width: 25px;
      display: block;
      margin-top: 5px;
      padding: 2px;
      border-radius: 3px;

      font-size: $onepage-font-size-s-a3 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a3 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a3 !important;
      }

      &.active {
        color: $white;
        background: $primary;
      }
    }

    &.zoom-view {
      margin-top: 10px;

      .btn {
        color: #a8a6ac;
        background: #ebebec;
        border-color: #ebebec;
        font-size: 12px;
        color: #000000;
        width: 40px;
        padding: 5px;
        display: block;
        margin-top: 10px;
        border-radius: 5px;

        &.active {
          color: $white;
          background: $primary;
        }
      }
    }
  }

  .tad-button {
    font-size: $onepage-font-size-s-a3 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a3 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a3 !important;
    }

    color: $gray-500 !important;

    &.zoom-view {
      font-size: 12px;
    }
  }

  .tad-circle-btn {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $gray-300;
    // margin-left: 4px;
    // margin-right: 4px;

    &.zoom-view {
      width: 10px;
      height: 10px;
      border-radius: 8px;
      // margin-left: 7.5px;
      // margin-right: 7.5px;
    }

    &.active {
      background-color: #000000;
    }
  }

  .tad-extra-circle-btn {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $gray-300;

    &.zoom-view {
      width: 10px;
      height: 10px;
      border-radius: 8px;
    }

    &.active {
      background-color: #000000;
    }
  }

  .button-circle-btn {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    // margin-left: 4px;
    // margin-right: 4px;
    border: 0.5px solid $gray-300;

    &.zoom-view {
      width: 10px;
      height: 10px;
      border-radius: 8px;
      border: 1px solid $gray-300;
      // margin-left: 7.5px;
      // margin-right: 7.5px;
    }

    &.active {
      border: 0.5px solid #000000;

      &.zoom-view {
        border: 1px solid #000000;
      }
    }
  }

  .tire-manage-form {
    .-color-list-wrapper {
      position: absolute;
      left: 150px;
      z-index: 2;
      &.-hide {
        opacity: 0;
        visibility: hidden;
      }
      &.-show {
        opacity: 1;
        visibility: visible;
      }
    }

    .color-list-manage {
      width: 200px;
    }

    .color-list-manage-box {
      display: flex;
      flex-wrap: wrap;
    }

    .-color-picker-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      &.-hide {
        opacity: 0;
        visibility: hidden;
      }
      &.-show {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .normal-form {
    p {
      font-size: 9px;
      font-weight: bold;
      margin-bottom: 5px;
      margin-left: 15px;
    }

    font-size: $onepage-font-size-s-a5 !important;

    @media (min-width: 1920px) {
      font-size: $onepage-font-size-m-a5 !important;
    }

    @media (min-width: 2560px) {
      font-size: $onepage-font-size-l-a5 !important;
    }

    .-color-picker-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      &.-hide {
        opacity: 0;
        visibility: hidden;
      }
      &.-show {
        opacity: 1;
        visibility: visible;
      }
    }

    .react-select-container,
    .react-select__control,
    .react-select__indicators,
    .react-select__value-container {
      height: 30px;
      min-height: 30px;
    }

    .react-select__value-container {
      width: 40px;
    }

    .wire-size-detail {
      height: 60px !important;
      width: 265px;
      margin-left: 10px;
      padding: 5px !important;
      padding-right: 30px !important;

      font-size: $onepage-font-size-s-a5 !important;

      @media (min-width: 1920px) {
        font-size: $onepage-font-size-m-a5 !important;
      }

      @media (min-width: 2560px) {
        font-size: $onepage-font-size-l-a5 !important;
      }
    }

    .form-group {
      margin-bottom: 0 !important;
      margin-top: 10px;
    }

    &.zoom-view {
      p {
        font-size: 14px !important;
        margin-bottom: 5px;
        margin-left: 15px;
      }

      font-size: 14px;

      .react-select-container,
      .react-select__control,
      .react-select__indicators,
      .react-select__value-container {
        height: 40px;
        min-height: 40px !important;
      }

      .react-select__value-container {
        width: 120px;
      }

      .wire-size-detail {
        height: 40px;
        width: 260px;
        margin-left: 10px;
        font-size: 14px;
        padding: 5px !important;
        padding-right: 30px !important;
      }

      .form-group {
        margin-bottom: 0 !important;
        margin-top: 10px;
      }
    }
  }

  .aligner-form {
    p {
      font-weight: bold;
    }

    font-size: 10px;

    .badge {
      font-size: 8px !important;
      color: #000000 !important;
    }

    .n-input {
      height: 30px;
      width: 50px;
      font-size: 8px;
      padding: 5px !important;
    }

    .react-select-container,
    .react-select__control,
    .react-select__indicators,
    .react-select__value-container {
      height: 25px;
      min-height: 25px !important;
    }

    // .react-select-container {
    //     width: 100px;
    // }

    .react-select__value-container {
      // margin-left: 10px;
    }

    .slick-prev {
      left: -20px !important;
    }

    .slick-next {
      right: 0 !important;
    }

    &.wire-tire-sm {
      input {
        height: 25px !important;
      }
    }

    &.zoom-view {
      p {
        font-size: 14px !important;
      }

      font-size: 14px;

      .badge {
        font-size: 11px !important;
      }

      .n-input {
        height: 40px;
        width: 70px;
        font-size: 14px;
        padding: 5px !important;
      }

      .react-select-container,
      .react-select__control,
      .react-select__indicators,
      .react-select__value-container {
        height: 40px;
        min-height: 40px !important;
      }

      .react-select__value-container {
        margin-left: 10px;
      }
    }

    .custom-link {
      text-decoration: underline !important;
      cursor: pointer;
      color: #868e96 !important;
    }

    .aligner-form-sm {
      p,
      input {
        font-size: $onepage-font-size-s-a5 !important;

        @media (min-width: 1920px) {
          font-size: $onepage-font-size-m-a5 !important;
        }

        @media (min-width: 2560px) {
          font-size: $onepage-font-size-l-a5 !important;
        }
      }
    }
  }
}

.next-booking-container {
  flex: 1;
  border: 1px solid #e3eaef !important;
  box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h5 {
    text-align: center;
    margin-bottom: 20px;
  }

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .col {
    border: 1px solid #e3eaef;
    padding: 10px;
  }

  .next-booking-detail-container {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  }
}

.payment-top-container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .payment-top-container {
    display: block;
  }

  .next-booking-container {
    margin: 0 !important;
    margin-top: 8px !important;

    h5 {
      text-align: left;
    }

    .col {
      border-width: 0;
      padding: unset;
    }
  }
}

.treatment-record-modal-custom-width {
  margin: 5px auto;

  @media (min-width: 576px) {
    max-width: 900px;
  }

  @media (min-width: 992px) {
    max-width: 1180px;
  }

  @media (min-width: 1200px) {
    max-width: 1900px;
  }

  .modal-content {
    // height: 885px;
  }

  .close {
    color: #000000 !important;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.treatment-refer-modal {
  .modal-title {
    color: #000;
    font-size: 18px;
  }

  .modal-header {
    padding-bottom: 5px;
    border: 0;
  }

  .treatment-refer-wrapper {
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: center;
  }

  .form-wrapper {
    font-size: 11px;
  }

  th {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  td {
    vertical-align: middle;
  }

  th,
  td {
    border-bottom: 1px solid #f6f6f6;
  }

  .modal-footer {
    justify-content: space-between;
  }

  .disabled {
    background-color: $gray-100;

    td {
      opacity: 0.7;
      cursor: not-allowed;
    }

    :first-child {
      opacity: 1;
      cursor: default;
    }
  }

  .close-btn-position {
    position: absolute;
    top: 10px;
    right: 15px;

    .fa-times {
      color: #bbb8c1;
    }
  }
}

.treatment-refer-table {
  th {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #3562fa !important;
    color: white !important;
  }
}

.forward-modal {
  button {
    min-width: 120px;
  }
  .modal-footer {
    border: 0;
  }
  .fa-exclamation-circle {
    font-size: 70px;
    color: #d4daf1;
  }
  .close-modal {
    position: absolute;
    top: 10px;
    right: 15px;

    .fa-times {
      color: #bbb8c1;
    }
  }
  .head-line {
    width: 100%;
    height: 15px;
    background-color: $primary;
  }
  .modal-body {
    text-align: center;
  }
  .modal-content {
    border-radius: 10px;
    overflow: hidden;
    border: 0;
  }
  .modal-title {
    color: black;
  }
}

.treatment-refer-table-container {
  margin-top: 1rem;
  border: 1px solid #e3eaef !important;

  td {
    vertical-align: middle;
  }
}

.payment-refer-header {
  .sub-title {
    color: #bac2cb;
    font-size: 12px;
  }

  h6 {
    font-size: 15px;
  }

  a {
    font-size: 14px !important;
  }

  .flex-baseline {
    display: flex;
    align-items: baseline;
  }
}

.next-visit-tag {
  border-radius: 5px;
  text-align: center;
}

.treatment-history-plan-form {
  .input-shortcut-form {
    textarea {
      height: 100px;
    }

    .border-primary {
      border: 1px solid $primary !important;
    }
  }

  .custom-link {
    text-decoration: underline !important;
    cursor: pointer;
    color: #868e96 !important;
  }

  font-size: $onepage-font-size-s-a3 !important;

  @media (min-width: 1920px) {
    font-size: $onepage-font-size-m-a3 !important;
  }

  @media (min-width: 2560px) {
    font-size: $onepage-font-size-l-a3 !important;
  }
}

.extra-height-table .table-responsive {
  padding-bottom: 120px;
}

.waiting-payment-approve-modal {
  .close {
    color: $gray-600 !important;
  }

  .btn-sm {
    border-radius: 20px;
  }
}

.sm-papercanvas-btn {
  button {
    width: 25px !important;
    height: 25px !important;
    font-size: 9px !important;
  }
}

.payment-table-container {
  table {
    th,
    td {
      padding: 5px 10px;
    }
  }
}
