$font-size-smaller: $font-size-base * .75 !default;
$font-size-small: $font-size-base * .875 !default;
$font-size-normal: $font-size-base !default;
$font-size-normal-to-big: $font-size-base * 1.125 !default;
$font-size-big: $font-size-base * 1.25 !default;
$font-size-bigger: $font-size-base * 1.5 !default;
$font-size-biggest: $font-size-base * 2 !default;

$onepage-font-size-s-a1: 18px !default;
$onepage-font-size-s-a2: 16px !default;
$onepage-font-size-s-a3: 14px !default;
$onepage-font-size-s-a4: 12px !default;
$onepage-font-size-s-a5: 12px !default;

$onepage-font-size-m-a1: 18px !default;
$onepage-font-size-m-a2: 16px !default;
$onepage-font-size-m-a3: 14px !default;
$onepage-font-size-m-a4: 12px !default;
$onepage-font-size-m-a5: 12px !default;

$onepage-font-size-l-a1: 18px !default;
$onepage-font-size-l-a2: 16px !default;
$onepage-font-size-l-a3: 14px !default;
$onepage-font-size-l-a4: 12px !default;
$onepage-font-size-l-a5: 12px !default;
