.react-datepicker__day--keyboard-selected {
    border-radius: 0;
    background-color: #fff;
    color: #000;
}

.react-datepicker__input-container .form-control {
    border-color: hsl(0, 0%, 80%) !important;
}

.react-datepicker-popper {
    z-index: 1001 !important;
}