$menu-line-color: $primary; // Variable for line color, easy to change
$menu-line-width: 2px; // Line width

.inventory-sub-menu {
  list-style: none;
  padding: 0;

  li {
    position: relative; // Needed for absolute positioning of pseudo-elements

    // Style for sub-menu items
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: $menu-line-width;
      background-color: $primary;
    }

    // Adding horizontal line to the first li element of each sub-menu
    &:first-child::before {
      top: 50%; // Start the vertical line from the middle
    }
  }
}

.inventory-sub-menu {
  margin-top: 4px;

  li {
    padding-left: 20px; // Indent sub-menu items
    padding-top: 8px;

    // Additional styles for nested sub-menus
    &.has-sub > .sub-menu {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        height: 10px;
        width: $menu-line-width;
        background-color: $menu-line-color;
      }

      &::after {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        width: 20px; // Horizontal connection width
        height: $menu-line-width;
        background-color: $menu-line-color;
      }
    }
  }

  // Styling for <a> tags
  $color-default-link: $theme-font-color; // Variable for line color, easy to change
  $color-active-link: $primary;
  a {
    color: $color-default-link; // Normal state color
    text-decoration: none; // Optional: removes underline

    &.active {
      color: $color-active-link; // Active state color
      font-weight: bold;
    }
  }
}
