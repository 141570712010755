.family-group-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.buttom-action {
  cursor: pointer;
  background-color: $secondary;
  &:hover {
    background-color: #f35f90;
    border-color: #f35f90;
  }
}

.family-group thead th {
  background-color: $primary;
  border: 0;
  color: $white !important;
}

.family-group-table-radius {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
}

.family-group-pagination .page-link {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.family-group-action-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}