.warehouse .nav-tabs {
  .nav-item {
    padding: 1.5rem;
  }

  .nav-link {
    background-color: transparent !important; // Force transparency

    &.active {
      background-color: transparent !important; // Force transparency
    }
  }
}

.storage-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  margin: 24px;
}

.large-div {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-text {
  font-size: 24px;
  font-weight: bold;
}

.custom-dropdown-item {
  transition: box-shadow 0.3s ease;
}

.custom-dropdown-item:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  background-color: $secondary !important;
}

.toast-list {
  list-style-type: none; /* Remove default bullet points */
}

.toast-list li {
  margin-bottom: 4px; /* Add some space between items */
}
