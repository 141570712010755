.treatment-refer-component-table {
  th {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #E8E8E8 !important;
    color: black !important;
  }
  
  td {
    border: 0;
    vertical-align: middle;
  }
  
  tbody {  
    tr {
      border: 2px solid #F6F6F6;
    }
  
    .date-container {
      border-bottom: 0;

      .dropdown-toggle::after {
        display:none;
      }

      .dropdown-toggle:active {
        background-color: transparent !important;
        border-color: transparent !important;
        color: black;
      }

      .dropdown-toggle-container {
        background-color: transparent;
        border-color: transparent;
        color: black;
        font-size: 20px;
        width: unset !important;
        padding: 0 !important;
      }
    }
  
    .last-item {
      border-top: 0;
    }
  
    .row-item {
      border-top: 0;
      border-bottom: 0;
    }
  
    .space {
      height: 10px;
      border: 0;
    }

    .warning-text {
      color: #FD6460;
    }

    .success-text {
      color: #23DB7E;
    }

    .panding-text {
      color: #5F1CD0;
    }

    .link-text {
      text-decoration: underline;
      cursor: pointer;
      color: #000;
    }
  }
}

.modal-alert {
  button {
    min-width: 120px;
  }

  .modal-footer {
    border: 0;
  }

  .fa-exclamation-circle {
    font-size: 70px;
    color: #D4DAF1;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 15px;

    .fa-times {
      color: #BBB8C1
    }
  }

  .head-line {
    width: 100%;
    height: 15px;
    background-color: $primary;
  }

  .modal-body {
    text-align: center;
  }

  .modal-content {
    border-radius: 10px;
    overflow: hidden;
    border: 0;
  }

  .modal-title {
    color: black;
    font-size: 25px;
  }

  .modal-alert-input-container {
    text-align: left;
    p {
      margin: 0;
    }
    textarea {
      height: 160px;
    }
  }
}

.treatment-refer-list-action-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;

  .fa-circle-xmark {
    color: #FD6460;
  }

  .fa-circle-check {
    color: #23DB7E;
  }

  .fa-rotate-left {
    color: $primary;
  }

  &.justify-center {
    justify-content: center;
  }
}

.show-treatment-refer-btn {
  background-color: #FB3C7C;
  color: white;
  height: 100px;
  width: 30px;
  display: flex;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  bottom: 0;
  right: -30px;

  &:hover {
    background-color: #e02a67;
  }
}

.treatment-refer-list-modal {
  .close-btn-position {
    position: absolute;
    top: 10px;
    right: 15px;
    
    .fa-times {
        color: #BBB8C1;
    }
  }

  .modal-title {
    color: $primary;
  }
}

.treatment-refer-follow-up-card {
  border-radius: 10px;

  h4 {
    font-size: 22px;
  }

  td {
    white-space: wrap;
    max-width: 100px;
  }

  th {
    background-color: #F5F6F8 !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  tr {
    cursor: pointer;
  }
}

.treatment-refer-tag-container {
  height: 100%;

  .treatment-refer-tag {
    background-color: #FD6460;
    color: white;
    border-radius: 15px;
    margin-top: 10px !important;
  }
}

#treatment-refer-popover {
  max-width: calc(100vw - 500px);
  border-radius: 10px;
  border-width: 0;

  .treatment-refer-popover-header {
    font-size: 20px;
    color: $primary;
  }

  hr {
    margin: 0;
  }
}

.noti-treatment-refer {
  .noti-treatment-refer-tag {
    border-radius: 5px;
    color: white;
  }
  .underline-text {
    text-decoration: underline;
  }
  .media-body {
    line-height: 1.5;
  }
}