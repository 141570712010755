.x-member-group-list {
    display: flex;
    align-items: center;

    .-img {
        width: 40px;
        height: auto;
        border-radius: 50%;
        border: 1px solid #7c7c7c;
    }
    .-img-wrapper {
        flex: 0 0 auto;
        margin-right: 1.5rem;
    }
    .-detail-wrapper {
        flex: 1 1 auto;
        .-title {
            color: $primary;
        }
        .-position {
            margin-top: -4px;
            font-size: $font-size-smaller;
        }
    }
    .-remove-btn {
        font-size: $font-size-big;
        color: $secondary;
        &:hover {
            color: darken($secondary, 15%);
        }
    }
}
