.print {
  &-container {
    margin: 10mm;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width:  calc(100% - 20mm);
    @media print {
      opacity: 1;
    }
    h2,h3,h4,h5,h6 {
      font-weight: bold;
    }

  }
  &__content-filling {
    border-bottom: 1px solid black;
    display: inline-flex;
    width: 200px;
    height: 25px;
    padding: 0px 0.5rem;
    position: relative;
    justify-content: center;
    align-items: flex-end;
    &--sm {
      width: 100px;
      height: 20px;
    }
  }
  &__signature-container {
    width: 200px;
  }
  &__signature-title {
    font-size: 1.25rem;
  }
  &__signature-image {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img {
      max-height: 45px;
    }
  }
  &__dental-care {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-gap: 2rem;
      column-fill: auto;
  }
}

.filling-flex-1 {
  flex: 1;
  justify-content: flex-start;
  padding: 0 1rem;
}
