$stamp-time-line-icon-size: 50px;

.x-stamp-time-line-component-container {
    .navbar-nav {
        flex-direction: row;
        width: 90%;
        margin: 1rem auto;
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    .nav-item {
        flex: 0 0 20%;
        text-align: center;
        .-date {
            margin-top: 1rem;
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
        }
        .-stamp-wrapper {
            display: inline-block;
            margin-top: .5rem;
            font-size: $font-size-small;
            text-align: left;
        }
        &.-active {
            color: $secondary;
            .-date,
            .-stamp-wrapper {
                transform: scale(1.2);
            }
        }
    }

    .-icon-wrapper {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 5px;
            background: #edeff1;
            transform: translate(-50%, -50%);
        }
    }

    .-circle-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $stamp-time-line-icon-size;
        height: $stamp-time-line-icon-size;
        margin: 0 auto;
        font-size: $font-size-normal-to-big;
        background: $white;
        border: 5px solid #edeff1;
        border-radius: 50%;
        &.-active {
            border-color: $secondary;
            transform: scale(1.2);
        }
    }
}
