.x-assessment-form-show-container {
    .-form-wrapper {
        display: flex;
        align-items: center;
        font-size: $font-size-small;
        .-span {
            width: 110px;
        }
        .-form-control {
            flex: 1;
        }
    }

    .-score-wrapper {
        .navbar-nav {
            font-size: $font-size-small;
        }
        .nav-item {
            display: flex;
            .-prefix {
                width: 130px;
            }
        }
    }

    .-choices-wrapper {
        font-size: $font-size-small;
    }

    .-summary-wrapper {
        font-size: $font-size-small;
        .-form-individual {
            width: 80px;
            font-size: $font-size-smaller;
        }
    }
}

.x-assessment-form-create-container {
    .-choice-index {
        min-width: 20px;
    }

    .-item-form {
        height: calc(.5em + 1.25rem + 2px);
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border: none !important;
    }

    .-form-individual {
        width: 70px;
        &-select {
            width: 150px;
            text-align: center;
        }
        &-score {
            width: 50px;
            height: calc(.5em + 1.25rem + 2px);
            padding: 0 !important;
            text-align: center;
        }
    }

    .-form-underline {
        width: 100px;
        height: calc(1em + 1.25rem + 2px);
        padding: 0 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0;
        text-align: center;
    }
}
