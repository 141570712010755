.x-hr-employee-show-container {
    max-width: 1000px;

    .accordion > .card {
        overflow: unset;
    }

    .card {
        margin-bottom: 20px;
        border-radius: 10px !important;
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $font-size-big;
            color: $primary;
            border: 2px solid $primary;
            border-radius: 10px;
            transition: all .2s;
            .fas {
                transition: transform .3s;
            }
        }
        &.-active {
            .card-header {
                color: $white;
                background: $primary;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                .fas {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.x-hr-inner-section-title {
    display: inline-block;
    margin-bottom: 10px;
    color: $primary;

    .form-control {
        &::placeholder {
            color: rgba($primary, .8);
        }
    }

    .-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        width: 26px;
        height: 26px;
        font-size: $font-size-smaller;
        color: $white;
        background: $secondary;
        border-radius: 50%;
    }
}

.x-hr-employee-info-container {
    .-profile-wrapper {
        position: relative;
        border-radius: 8px;
        border: 2px solid #d3d8de;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
        }
        .-text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            padding: 15px;
            text-align: center;
            background: rgba($white, .7);
        }
    }

    .-qr-btn {
        display: inline-block;
        margin-top: 10px;
        padding: 0 10px;
        .fas {
            margin-right: 10px;
            color: gray;
        }
        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    .form-label {
        display: block;
        margin-bottom: .25rem;
    }

    .form-control {
        height: calc(1em + 1.25rem + 2px);
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
}

.x-hr-employee-overtime-container {
    .form-control {
        height: calc(1em + 1.25rem + 2px);
    }

    .-salary {
        .form-group {
            display: flex;
            align-items: flex-end;
        }
        span {
            margin-left: 10px;
        }
    }

    .-form-individual {
        width: 70px;
        text-align: center;
    }

    .-form-individual-2 {
        width: 120px;
        text-align: center;
    }

    .-form-period-type {
        width: 150px;
    }
}

.x-hr-employee-check-time-container {
    .-remaining-detail-wrapper {
        padding-left: 6rem;
    }

    .-row-list-item {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .nav-item {
            display: flex;
            margin-top: .25rem;
        }
        .-title {
            width: 200px;
        }
        &:not(:last-child) {
            border-bottom: 2px dashed #edeff1;
        }
    }
}

.x-hr-employee-request-form-container {
    .-add-more-btn {
        font-size: $font-size-small;
    }
}

.x-hr-employee-status-container {
    .-check-form-wrapper {
        display: flex;
    }

    .-remove-btn {
        color: $secondary;
        background: transparent;
        border-width: 2px;
    }
}
