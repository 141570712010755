.x-dentist-crm-footer-action {
    position: fixed;
    bottom: 0;
    left: 190px;
    z-index: 99;
    width: calc(100vw - 190px);
    padding: 1rem 3rem;
    background-color: $white;

    .-btn {
        margin-bottom: 0;
        &.-btn-primary {
            background-color: #4ba09b;
            border-color: #4ba09b;
            &:hover {
                background-color: darken(#4ba09b, 7.5%);
                border-color: darken(#4ba09b, 7.5%);
            }
        }
        &.-btn-gray {
            background-color: #a5acab;
            border-color: #a5acab;
            &:hover {
                background-color: darken(#a5acab, 7.5%);
                border-color: darken(#a5acab, 7.5%);
            }
        }
    }
}
