.family-group-form-modal {
  .line {
    border-top: 2px solid #BAC2CB80
  }

  .title {
    text-align: left;
    font-size: 20px;
    color: black;
  }

  .description {
    color: #BAC2CB;
    font-size: 12px;
  }

  .modal-content {
    border-radius: 10px;
    border-width: 0;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    color: black;
    font-size: 25px;
  }
  
  .align-items-baseline {
    align-items: baseline;
  }
}

.member-card-container {
  display: flex;
  align-items: center;

  .description {
    color: #BAC2CB;
    font-size: 10px;
  }

  i {
    font-size: 20px;
    color: #BAC2CB;
  }

  img {
    border-radius: 15px;
    object-fit: cover;
  }
}

.family-group-hint-icon {
  color: #BAC2CB;
  cursor: pointer;
}

.hint-content {
  ol {
    padding-inline-start: 20px;
  }
}