.x-crm-treatment-images {
    .card {
        .-btn-dropdown {
            position: absolute;
            top: 5px;
            right: calc(.5rem + 5px);
            z-index: 1;
        }
        .-btn {
            display: flex;
            flex-direction: column;
            margin-right: 0;
            padding: 0;
            color: $body-color;
            background: transparent;
            border: none;
            border-radius: 0;
            overflow: hidden;
            .-created-at {
                font-size: .75rem;
                text-align: right;
            }
            .-title {
                font-size: .875rem;
                text-align: center;
            }
            &:hover {
                filter: brightness(.95);
            }
        }

        .-thumbnail-image-wrapper {
            flex: 1;
        }
    }

    .-btn-add-images {
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //height: 100%;
        //background: #eff0f2;
        //.-icon-wrapper {
        //    display: flex;
        //    align-items: center;
        //    justify-content: center;
        //    width: 40px;
        //    height: 40px;
        //    border: 1px dashed #bdbaba;
        //    .fa {
        //        color: #bdbaba;
        //    }
        //}
    }
}

.x-modal-treatment-images-form {
    .-detail-row {
        display: flex;
        align-items: center;
        .-label {
            min-width: 120px;
        }
        .form-group {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .-box-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .-box {
        position: relative;
        width: calc(100% / 7);
        min-height: 210px;
        margin: .5rem .25rem;
        border: 1px solid #8f8f8f;
        .-box-title {
            text-align: center;
            padding: 4px 2px;
            font-size: .75rem;
            background: #f2f2f2;
        }
        .-box-action {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            .-btn {
                position: relative;
                min-width: 80px;
                margin-bottom: .5rem;
                padding: 2px;
                font-size: .625rem;
                color: $body-color;
                background: $white;
                border-radius: 5px;
                border-color: $body-color;
                overflow: hidden;
                &:hover {
                    color: $white;
                    background: $primary;
                    border-color: $primary;
                }
            }
            input[type=file] {
                position: absolute;
                top: 0;
                right: 0;
                min-width: 100%;
                min-height: 100%;
                font-size: 100px;
                text-align: right;
                filter: alpha(opacity=0);
                opacity: 0;
                outline: none;
                background: white;
                cursor: inherit;
                display: block;
            }
        }
    }
}
