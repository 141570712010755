.x-no-result-container {
    padding: 1rem;
    text-align: center;

    .fas {
        font-size: $font-size-biggest;
    }

    .-title {
        font-size: $font-size-bigger;
        font-weight: $font-weight-bold;
    }
}
