.badge {
  white-space: normal !important;
}

.custom-modal-dialog {
  width: auto;
  max-width: 80%;
  margin: auto;
}

.custom-modal-body {
  height: 400px;
}

.white-title .dropdown-toggle {
  color: white !important;
}

/* Hide the arrow icon */
.no-arrow .dropdown-toggle::after {
  display: none;
}

.inventory .dropdown-toggle {
  width: 120px !important;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words */
}
