.x-hr-employee-index-container {
    .-profile-card-wrapper {
        padding: 0 .5rem;
    }

    .slick-list {
        margin: 0 .5rem;
    }

    .slick-track {
        margin-left: 0;
        margin-right: 0;
    }
}

.employee-list-container {
    display: flex;
    flex-wrap: wrap;

    &>a {
        width: 200px;
        margin-bottom: 0.5rem;
    }
}

.x-profile-card-container {
    position: relative;
    width: 100%;
    color: $black;
    background: $white;
    border-radius: 8px;
    border: 2px solid #d3d8de;
    overflow: hidden;

    .-profile-image {
        width: 100%;
        height: auto;
    }

    .-details-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
        padding: 10px;
        background: rgba($white, .7);
    }
}