.x-ray-film-base {
    .-main-container {
        display: flex;
    }

    .-main-container-sidebar-wrapper {
        width: 240px;
    }

    .-main-container-content-wrapper {
        flex: 1;
    }

    .-box-info {
        padding: .5rem 1rem;
        background: $white;
        border: 1px solid $border-color;
        border-radius: 8px;
        box-shadow: 0 0 5px rgba($black, .1);
        .-label {
            min-width: 60px;
            color: $primary;
        }
        .-value {
            color: $body-color;
        }
        &.-danger {
            .-label,
            .-value {
                color: $danger;
            }
        }
    }

    .-form-box-base {

    }

    .-form-box-title-base {
        display: block;
        margin-bottom: .5rem;
    }

    .-form-box-list-base {
        overflow: hidden;
        background: $white;
        border: 1px solid $border-color;
        border-radius: 8px;
        .navbar {
            padding: 0;
        }
    }

    .-form-box-list-title-base {
        display: block;
        padding: .5rem 1rem;
        border-bottom: 1px solid $border-color;
    }

    .-select-list-item {
        width: 100%;
        color: $body-color;
        background: transparent;
        border: none;
        border-radius: 0;
        transition: all .2s;
        text-align: left;
        &:hover {
            background: #ced5ef;
        }
        &.-active {
            background: #ced5ef;
        }
    }

    .-capture-column {
        border-right: 1px solid $border-color;
    }
}
