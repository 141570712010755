.info-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  font-size: large;
}

.info-item strong {
  min-width: 200px;
  text-align: left;
  margin-right: 8px;
}

.info-item span {
  flex: 1;
}

.product-table thead th {
  background-color: $primary;
  border: 0;
  color: $white !important;
}

.product-table thead th:first-child {
  border-top-left-radius: 0.5rem;
}

.product-table thead th:last-child {
  border-top-right-radius: 0.5rem;
}

.product-table th,
.product-table td {
  padding: 8px;
  vertical-align: middle;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: large;
}

.product-table th.text-center {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.product-table td.text-center {
  text-align: center;
  vertical-align: middle;
  padding: 4px !important;
}

.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  max-height: 200px;
}

.signature-box {
  width: 30%;
  border: 1px solid black;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signature-area {
  height: 80px;
  border-bottom: 1px solid black;
  margin: 10px 0;
}

.signature-image {
  max-height: 80px;
}

.signature-logo {
  width: 30%;
  text-align: center;
  align-content: center;
  border: 1px solid black;
  padding: 10px;
}

.signature-date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.signature-sign-line {
  flex-grow: 1;
  height: 1px;
  background-color: black;
  margin-left: 10px;
  margin-top: 10px;
}
