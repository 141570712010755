.x-business-setting-index-container {
    .-holiday-section-wrapper {
        .react-datepicker {
            width: 90%;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            &__month-container {
                width: 100%;
            }
            &__day,
            &__day-name {
                width: calc((100% / 7) - 10px);
            }
        }
    }

    .-weekend-wrapper {
        .-choices-row-wrapper {
            width: 80%;
            margin: auto;
            @include media-breakpoint-down(md) {
                width: 90%;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .-day-leaves-wrapper {
        .-form-individual {
            width: 150px;
        }
    }
}
