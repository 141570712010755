.x-finger-print-device-item-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .-img-wrapper {
        width: 120px;
        min-width: 120px;
        @include media-breakpoint-down(md) {
            width: 100px;
            min-width: 100px;
        }
        @include media-breakpoint-down(xs) {
            width: 80px;
            min-width: 80px;
        }
        .-img {
            width: 100%;
            height: auto;
        }
    }

    .-detail-wrapper {
        padding: 1rem;
        background: #f5f5f6;
        .-title {
            font-weight: bold;
        }
    }
}

.x-finger-print-show-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $white;
    z-index: 1030;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: all .3s;

    &.-show {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .-close {
        position: absolute;
        top: 1rem;
        right: 2rem;
        font-size: 3rem;
        color: $primary;
    }

    .-date {
        font-size: 2.25rem;
    }

    .-time {
        margin-top: -1rem;
        font-size: 7rem;
    }

    .-sec {
        font-size: 3.5rem;
        color: $text-muted;
    }

    .-description {
        margin-top: -1rem;
        font-size: 1.75rem;
    }

    .-action-wrapper {
        margin-top: -2rem;
    }

    .-btn {
        .fas {
            font-size: 5rem;
        }
        div {
            min-width: 120px;
            padding: 8px 20px;
            font-size: 1rem;
            color: $white;
            border-radius: 10px;
        }
    }

    .-check-in-btn {
        .fas {
            color: $success;
        }
        div {
            background: $success;
        }
    }

    .-check-out-btn {
        .fas {
            color: $danger;
        }
        div {
            background: $danger;
        }
    }

    .-new-print-btn {
        width: 150px;
        border: 1px solid $black;
        margin-top: 1rem;
        .fas {
            display: block;
            margin-top: .5rem;
            font-size: 5rem;
        }
        span {
            display: block;
            margin-top: .5rem;
            font-size: 1rem;
        }
    }
}
