.x-crm-approve-docs {
    .-inner-tab-container {
        display: flex;
        .-sidebar-wrapper {
            width: 170px;
            margin-right: 1rem;
            .nav-link {
                padding-top: .75rem;
                padding-bottom: .75rem;
                margin-bottom: .5rem;
                color: $black;
                background: #f2f2f2;
                border-left: 5px solid transparent;
                transition: all .2s;
                &:hover {
                    border-left-color: rgba($primary, .5);
                }
                &.-active {
                    background: #fcf9f9;
                    border-left-color: $primary;
                }
            }
        }
        .-content-wrapper {
            flex: 1;
            padding: 1rem;
            background: #fcf9f9;
        }
    }
}
