.x-request-report-container {
    $border-width: 1px;
    $border-color: #d6d6d6;
    $border-radius: 8px;
    .nav-tabs {
        border-bottom: none;
        .nav-link {
            padding: .3rem 1.5rem;
            font-size: $font-size-small;
            color: $body-color;
            background: #eeeff1;
            border-top: $border-width solid $border-color;
            border-bottom: $border-width solid $border-color;
            border-right: $border-width solid $border-color;
            border-radius: 0;
            &:first-child {
                border-left: $border-width solid $border-color;
                @include border-left-radius($border-radius)
            }
            &:last-child {
                @include border-right-radius($border-radius)
            }
            &.active, &.active:hover {
                color: $primary;
                background: #cad1ed;
            }
            &:hover {
                background: lighten(#cad1ed, 7.5%);
            }
        }
    }

    .tab-content {
        margin-top: 1.5rem;
    }
}
