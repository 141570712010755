.credit-modal {
  .credit-section-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .credit-section {
    border-radius: 5px;
    border: 1px solid #adaeb5;
  }

  .service-table-container {
    border-radius: 0;
    box-shadow: none;

    th {
      background-color: #f5f6f8 !important;
      color: #676879 !important;
      border-top: 1px solid #adaeb5;
      border-bottom: 1px solid #adaeb5;
    }
  }

  .table-wrapper {
    border: 1px solid #adaeb5;
    border-radius: 5px;
  }
}
