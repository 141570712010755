.tutorial-modal {
    video {
        border-radius: 8px;
        overflow: hidden;
    }

    .modal-content {
        background-color: transparent;
        border: 0
    }

    .close-btn {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary;
        color: $white;
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 50%;
        cursor: pointer;
    }
}

.tutorial-btn {
    position: fixed;
    bottom: 50px;
    width: 60px;
    height: 60px;
    background-color: $secondary;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: right 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 25px;
    z-index: 4;
}

.hover {
    &:hover {
        transition: transform .2s;
        transform: scale(1.1)
    }
}

.tutorial {
    .tutorial-thumbnail {
        position: relative;

        .fa-play {
            font-size: 20px;
            color: $white;
        }

        .play-btn {
            position: absolute;
            top: calc(50% - 20px);
            right: calc(50% - 20px);
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.4);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }

    .duration {
        font-size: 12px;
        color: $gray-500;
    }

    .tutorial-list {
        transition: transform .2s;

        .tag {
            font-size: 10px;
            background-color: #f00;
            border-radius: 8px;
            padding: 0 5px;
            color: $white;
        }
    }

    .tutorial-list:hover {
        transform: scale(1.03)
    }
}