.service-calendar {
  font-family: "Mitr" !important;
  font-size: 16px;
  border-width: 0 !important;
  background: transparent !important;
  width: 100% !important;

  .react-calendar__viewContainer {
    padding: 20px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #C4C4C4;
  }

  .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation {
    margin-bottom: 5px;
  }

  .dot {
    width: 7px;
    height: 7px;
    border-radius: 7px;
    margin: 2px;
  }

  .red-bg {
    background-color: #FD6460;
  }

  .green-bg {
    background-color: #7DE16D;
  }

  .react-calendar__tile--now {
    background-color: white;
    color: $primary;
  }

  .react-calendar__tile--range {
    background-color: #B894FF80;
    border-radius: 0 !important;
    color: white;
  }

  .react-calendar__tile--rangeStart {
    background-color: $primary;
    color: white;
    border-top-left-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }

  .react-calendar__tile--rangeEnd {
    background-color: $primary;
    color: white;
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
  }

  .service-calendar-tile {
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #B894FF80;
  }

  abbr {
    text-decoration: none;
    font-weight: normal;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus  {
    background-color: $primary !important;
    color: white;
  }

  .react-calendar__tile:enabled:hover {
    background: #B894FF80;
    color: $primary;
  }

  .react-calendar__navigation__arrow {
    border-radius: 25px;
  }

  .react-calendar__navigation__label {
    border-radius: 8px;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #787B8B;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #B894FF80;
    color: $primary;
  }

  .react-calendar__tile:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    border-radius: 0;
  }

  .react-calendar__tile--hover {
    background-color: #B894FF80 !important;
    color: $primary;
  }
}