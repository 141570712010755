.product-info-footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white !important;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

/* Media query for screens with sidebar (min-width: 992px) */
@media (min-width: 992px) {
  .product-info-footer-fixed {
    left: 440px;
    width: calc(100% - 440px);
  }
}

.qr-container {
  display: grid;
  grid-template-areas:
    'item1 item1 item2'
    'item3 item4 item2';
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: 1fr 1fr;
  width: 500px;
  height: 150px;
  border: 1px solid #ccc;
}

.item1 {
  grid-area: item1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}

.item2 {
  grid-area: item2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
}

.item3 {
  grid-area: item3;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}

.item4 {
  grid-area: item4;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}

.product-qr-modal {
  .modal-content {
    width: fit-content;
  }
  .close {
    color: $theme-heading-color !important;
  }
}

.product-info-receipt-note-modal {
  .close {
    color: $theme-heading-color !important;
  }
  .modal-title {
    color: $theme-heading-color;
  }
}

.product-info-receipt-note-table-container {
  width: 600px;
  overflow-x: auto;
  table {
    width: 1500px;
    border: 1px solid $form-bg;
    thead {
      th {
        background-color: $form-bg;
        color: $theme-heading-color !important;
        text-align: center;
        align-content: center;
      }
    }
  }
}

.product-info-receipt-note-edit-icon-table-cell {
  position: relative;
}

.product-info-receipt-note-edit-icon {
  position: absolute;
  right: 30px;
  cursor: pointer;
}
