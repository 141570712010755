.treatment-refer-page-container {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;

  .header {
    h4 {
      margin: 0;
      font-weight: normal;
    }
  }

  hr {
    margin: 10px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .date-input-container {
    border: 1px solid #cccccc;
    height: 38px;
    border-radius: 4px;
  }

  .date-input {
    border: 0;
  }

  .footer-btn {
    background-color: #A3ACAB;
    border-color: #A3ACAB;
  }
}