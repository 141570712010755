.x-hr-card {
    border-radius: 6px;

    &.-border-left {
        border-left: 10px solid $primary;
    }

    .card-header {
        padding-top: 1rem;
        padding-bottom: .5rem;
        font-size: $font-size-big;
        font-weight: $font-weight-bold;
        color: $primary;
        border-bottom-width: 3px;
        border-bottom-color: #eeeff1;
    }
}
