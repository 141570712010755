.x-df-outer-wrapper {
    border: 1px solid $border-color;
    border-radius: 5px;

    .-header-wrapper {
        padding: .5rem 1rem;
    }

    .-content-wrapper {
        .table-responsive {
            min-height: unset;
        }
        table {
            margin-bottom: 0;
            td {
                padding-top: .4rem;
                padding-bottom: .4rem;
                vertical-align: middle;
            }
        }
    }
}

@mixin switch($res: 'sm') {
    $index: 1rem;
    $mainVal: 1rem;

    @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
    } @else if $res == 'lg' {
        $index: 3rem;
        $mainVal: 2rem;
    } @else if $res == 'xl' {
        $index: 4rem;
        $mainVal: 2.5rem;
    }

    .custom-control-label {
        padding-left: #{$index};
        padding-bottom: #{$mainVal};
    }

    .custom-control-label::before {
        top: 0;
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
    }

    .custom-control-label::after {
        top: 3px;
        left: -33px;
        width: calc(#{$mainVal} - 6px);
        height: calc(#{$mainVal} - 6px);
        border-radius: calc(#{$index} - (#{$mainVal} / 2));
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(calc(#{$mainVal} - 0.25rem));
    }

    input {
        width: calc(#{$index} + 0.75rem);
        height: $mainVal;
    }
}

.custom-switch.custom-switch-md {
    @include switch('md');
}
