.x-ray-treatment-refer-list {
    .x-ray-treatment-refer-table-container {
        background-color: white;
        border-radius: 10px;
        overflow: hidden;

        .header h4 {
            font-weight: normal;
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: 100px auto;
        align-items: center;
    }

    .date-input-container {
        border: 1px solid #cccccc;
        height: 38px;
        border-radius: 4px;
        background-color: white;
    }
    
    .date-input {
        border: 0;
        width: 100%;
    }

    .filter-container {
        display: flex;
        flex-flow: row wrap;
    }
    
    .left-column {
        width: 320px;
    }

    .tag {
        background-color: #E8E8E8;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
            background-color: #d2d2d2;
        }

        &.active {
            background-color: $primary; 
            color: white;
        }
    }

    .summary-section {
      justify-content: flex-end;

      .summary {
        color: $primary;
      }
    }
}