.product-card {
  border: 1px solid $border-color !important;
  border-radius: 8px;
  overflow: hidden;
}

.product-footer {
  background-color: $secondary !important;
  color: white;
  text-align: center;
  padding: 10px;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
}
