.x-requested-card-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    .-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        font-size: $font-size-biggest;
        color: $primary;
        background: #cad0ed;
    }

    .-detail-wrapper {
        flex: 1;
        padding: 10px 1rem;
        font-size: $font-size-smaller;
        background: #f5f5f6;
    }

    .nav-item {
        display: flex;
        .-title {
            width: 110px;
        }
    }

    .-can-apply {
        font-size: $font-size-smaller;
        color: $primary;
        background: transparent;
        border-radius: 5px;
        border-width: 2px;
    }
}
