.offcanvas {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -405px;
    width: 400px;
    max-width: 100%;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: right 0.3s ease-in-out;
    z-index: 1050;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: auto;
    max-height: 100vh;
    // z-index: 999999;
}

.offcanvas.show {
    right: 0;
}

.offcanvas-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1040;
    // z-index: 999989;
}

.offcanvas-backdrop.show {
    opacity: 1;
}