.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white !important;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Media query for screens with sidebar (min-width: 992px) */
@media (min-width: 992px) {
  .fixed-footer {
    left: 390px; /* Adjust based on your sidebar width */
    width: calc(100% - 390px); /* Adjust based on your sidebar width */
  }
}
