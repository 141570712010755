.x-ray-record {
    .-form-box-list-base {
        .nav-link {
            color: $body-color;
            transition: all .2s;
            &:hover,
            &.-active {
                color: $primary;
            }
        }
    }

    .-images-column-inner {
        display: flex;
        flex-wrap: wrap;
    }

    .-btn-image {
        width: calc(100% / 3 - 8px);
        margin: 1rem .25rem 0;
        padding: .4rem .4rem .25rem;
        color: $body-color;
        background: transparent;
        border-radius: 0;
        border-color: $body-color;
        @include media-breakpoint-down(lg) {
            width: calc(100% / 2 - 8px);
        }
        img {
            width: 100%;
            height: auto;
            transition: all .2s;
        }
        .-detail {
            display: flex;
            justify-content: space-between;
            margin-top: .25rem;
            font-size: $font-size-smaller;
        }
        &:hover {
            img {
                transform: scale(1.025);
            }
        }
    }

    .x-no-result-container {
        width: 100%;
    }
}

.x-records-modal {
    .-profile-info {
        display: flex;
        flex-direction: column;
        font-size: $font-size-small;
        color: $gray-600;
    }

    .-img-wrapper {
        height: 230px;
        margin-top: 1rem;
        text-align: center;
        img {
            width: auto;
            max-width: 100%;
            height: 100%;
        }
    }
}
