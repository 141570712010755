.x-search-with-advance-button {
    display: flex;
    margin-bottom: .5rem;
    .form-group {
        flex: 1;
        margin-bottom: 0;
    }
    .-btn-advance {
        margin-left: 1rem;
    }
}

.x-search-advance-box {
    padding: 1rem 2rem;
    border: 1px solid $border-color;
    border-radius: 5px;
    .-inner-wrapper {
        display: flex;
        .-title {
            padding-right: 2rem;
        }
    }
    .-form-wrapper {
        flex: 1;
        margin-left: 1rem;
        .react-datepicker-wrapper {
            width: 100%;
        }
    }
}

.x-teeth-selection-modal {
    .-upper-wrapper,
    .-bottom-wrapper {
        display: flex;
        max-width: 550px;
        margin: auto;
    }

    .-bottom-wrapper {
        margin-top: 2rem;
        .-teeth-item-wrapper {
            .-btn {
                order: 0;
            }
            .-img-container {
                order: 1;
            }
        }
    }

    .-teeth-item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% / 16);
        padding: 0;
        background: transparent;
        border: none;
        border-radius: 0;
        .-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            font-size: .55rem;
            line-height: 1.2;
            color: #a8a6ac;
            background: #ebebec;
            border-color: #ebebec;
            border-radius: 50%;
        }
        &:not(:disabled):not(.disabled):active {
            background: transparent;
        }
        &:hover {
            .-btn {
                background: rgba(95, 28, 208, 0.25);
            }
        }
        &.-active {
            .-btn {
                color: #fff;
                background: #5f1cd0;
            }
        }
    }

    .-img-container {
        width: 100%;
        .-img {
            width: 100%;
            height: auto;
        }
    }

    .-zone-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;
        //max-width: 380px;
        .-zone-btn {
            width: 120px;
            margin: .25rem .5rem;
            padding: 0.75rem 0.5rem;
            font-size: .75rem;
            color: #a8a6ac;
            background: #ebebec;
            border-color: #ebebec;
            &:not(.-active):hover {
                color: #fff;
                background: #5f1cd0;
            }
            &.-active {
                color: #fff;
                background: #5f1cd0;
            }
        }
    }

    //.-selection-wrapper {
    //    position: relative;
    //    .btn {
    //        position: absolute;
    //        padding: 0;
    //        background: transparent;
    //        border: none;
    //        border-radius: 0;
    //        &:hover {
    //            background: rgba($primary, .1);
    //        }
    //        &.-active {
    //            background: rgba($primary, .25);
    //        }
    //        &.-top {
    //            top: 0;
    //            left: 0;
    //            width: 100%;
    //            height: 30%;
    //        }
    //        &.-bottom {
    //            bottom: 0;
    //            left: 0;
    //            width: 100%;
    //            height: 30%;
    //        }
    //        &.-right {
    //            top: 30%;
    //            right: 0;
    //            width: 50%;
    //            height: 40%;
    //        }
    //        &.-left {
    //            top: 30%;
    //            left: 0;
    //            width: 50%;
    //            height: 40%;
    //        }
    //    }
    //}
}

.parent-user-icon {
  color: $primary-color;
}

.flex-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.flex-wrap-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

hr .side-menu-hr {
  border: 1px solid #BAC2CB;
  border-radius: 5px;
}
