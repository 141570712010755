.x-hr-shift-member-modal-container {
    .-inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 1.5rem;
    }

    .-profile-image-wrapper {
        width: calc(100% / 5);
        margin-bottom: 1.5rem;
        text-align: center;
        .-img {
            width: 100%;
            max-width: 70px;
            margin: 0 5px;
            height: auto;
            border: 1px solid #7c7c7c;
            border-radius: 50%;
        }
    }
}

.x-employee-request-show-modal-container {
    .-inner-body-wrapper {
        padding: 1rem 2rem;
        background: #f5f5f6;
        .-name {
            font-size: $font-size-big;
            font-weight: $font-weight-bold;
            color: $primary;
        }
    }

    .navbar-nav {
        font-size: $font-size-small;
    }

    .nav-item {
        display: flex;
        .-title {
            width: 140px;
            color: $black;
        }
        .-value {
            flex: 1;
        }
    }

    .-read-more {
        display: inline-block;
        margin-top: 1rem;
        color: $primary;
    }
}

.x-employee-request-form-create-modal-container {
    .modal-header {
        padding-bottom: 0;
    }

    .modal-body {
        padding-top: 0;
    }

    .tab-content {
        min-height: 450px;
    }

    .card-header-tabs {
        margin: 0 0 1rem;
        .nav-link {
            flex: 1 1 auto;
            text-align: center;
            color: #abb5bf;
            background-image: linear-gradient(to top, $secondary 2px, transparent 2px);
            background-size: 100% 100%, 100% 100%;
            &.active {
                color: $secondary;
            }
        }
    }

    .navbar-nav {
        .nav-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: $font-size-small;
            &:not(:first-child) {
                margin-top: 1rem;
            }
            .-title {
                width: 140px;
            }
            .-value {
                flex: 1;
            }
        }
    }

    .-form-individual {
        width: 120px;
    }
}
