.x-report-table {
    thead {
        th {
            color: $primary;
            background: #d6dbf1;
        }
    }

    tbody {
        td {
            font-size: $font-size-small;
        }
    }
}
