.x-sketch-drawer-wrapper {
    .-color-picker-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        &.-hide {
            opacity: 0;
            visibility: hidden;
        }
        &.-show {
            opacity: 1;
            visibility: visible;
        }
    }

    .-btn {
        width: 32px;
        height: 32px;
        margin-left: 5px;
        padding: 5px;
        font-size: 12px;
        color: $body-color;
        background: #eff0f2;
        border-color: #eff0f2;
        &.-active {
            color: $white;
            background: $primary;
            border-color: $primary;
        }
        &:not(.-active) {
            &:hover {
                background: rgba($primary, .25);
                border-color: rgba($primary, .25);
            }
        }
    }

    .-title-info-wrapper {
        font-size: .75rem;
        color: #b8b8b8;
    }
}
