.customer-info-card {
  position: relative;
  border: 2px solid #f1f2f4;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  font-size: 12px;

  .title {
    color: $primary;
    font-weight: bold;
    margin-right: 5px;
    white-space: nowrap;
  }

  .red-text {
    color: #fd6460;
    .title {
      color: #fd6460;
    }
  }

  .section-container {
    display: flex;
    flex-flow: row nowrap;

    a {
      color: $theme-font-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .note {
    text-wrap: wrap;
    font-family: "Mitr" !important;
  }

  .customer-info-card-profile {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  }

  @media only screen and (max-width: 1200px) {
    .use-responsive {
      display: block;
    }
  }

  &.onepage-layout {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
    background-color: #f5f7f8;

    font-size: 11px !important;

    .customer-info-card-profile {
      width: 35px !important;
      height: 35px !important;
    }
  }

  &.card-no-shadow {
    box-shadow: none !important;
    border-radius: 0 !important;
    border-width: 1px !important;

    font-size: 13px !important;

    .note {
      max-height: 120px;
      overflow: auto;
    }

    &.ortho-note {
      .note {
        max-height: 20px;
        margin-bottom: 2px !important;
        overflow: auto;
      }
    }
  }
}

.row-edge-wrap {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.calendar-customer-info-container {
  .flex-1 {
    flex: 1;
  }
}

.treatment-top-container {
  .customer-info-card {
    border-radius: 0;
    border: 1px solid #e3eaef !important;
  }
  .note {
    max-height: 140px;
    overflow: auto;
  }
}

.payment-modal-customer-info {
  flex: 1;

  .customer-info-card {
    border-radius: 0;
    border: 1px solid #e3eaef !important;
  }
  .note {
    max-height: 140px;
    overflow: auto;
  }
}

.ortho-treatment-plan-form {
  // .customer-info-container {
  .onepage-layout {
    font-size: 12px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .card-no-shadow {
    font-size: 12px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
