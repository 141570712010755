.x-holiday-list-item-container {
    display: flex;
    align-items: center;
    padding-bottom: .75rem;
    border-bottom: 1px dashed $text-muted;
    &:not(:first-child) {
        padding-top: .75rem;
    }

    .-content-wrapper {
        flex: 1;
    }

    .-note {
        margin-top: .25rem;
        color: $text-muted;
    }
}
