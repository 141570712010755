.x-employee-request-card-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2px;
    background: #f5f5f6;
    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    .-image-wrapper {
        flex: 0 0 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        .-img {
            width: 100%;
            height: auto;
        }
    }

    .-content-wrapper {
        flex: 1;
        padding: .75rem 1.5rem;
        .-name {
            font-size: $font-size-bigger;
            font-weight: $font-weight-bold;
            color: $primary;
        }
    }

    .-row-data-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: .2rem;
        font-size: $font-size-small;
        .-label {
            min-width: 120px;
            font-weight: $font-weight-bold;
        }
        &.-between {
            justify-content: space-between;
            .-label {
                min-width: 0;
            }
        }
        &.-small {
            font-size: $font-size-smaller;
            .-label {
                min-width: 0;
                font-weight: $font-weight-normal;
            }
        }
    }
}
