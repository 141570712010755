.x-hr-index-main-container {
    .-heading-section-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .-period-btn {
        padding: .5rem 1rem;
        font-size: $font-size-normal;
        color: #696b7d;
        background: $white;
        border: 2px solid #acb5bf;
        box-shadow: 0 2px 10px #acb5bf;
        transition: all .2s;
        &:active,
        &:hover {
            color: $white !important;
            background: #acb5bf !important;
            border: 2px solid #acb5bf !important;
        }
    }

    .-slide-btn-group {
        margin-left: 1rem;
        border: 2px solid #acb5bf;
        border-radius: 8px;
        box-shadow: 0 2px 10px #acb5bf;
        overflow: hidden;
        .-btn {
            position: relative;
            padding: .5rem 1rem;
            font-size: $font-size-normal;
            color: #696b7d;
            background: $white;
            border: none;
            border-radius: 0;
            transition: all .2s;
            &:active,
            &:hover {
                color: $white !important;
                background: #acb5bf !important;
            }
            &:first-child {
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 2px;
                    height: 50%;
                    background: #acb5bf;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .-current-range-wrapper {
        margin-left: 2rem;
        .-title {
            font-size: $font-size-big;
            color: $black;
        }
        .-sub-title {
            font-size: $font-size-small;
            color: #696b7d;
        }
    }
}

.x-hr-branch-card-container {
    background: $white;
    border: 1px solid #edeff1;
    border-radius: 10px;

    .-title {
        font-size: $font-size-big;
        font-weight: $font-weight-bold;
        color: $primary;
    }

    .-btn {
        font-size: $font-size-normal-to-big;
        color: $secondary;
        background: transparent;
        border-width: 2px;
        box-shadow: 0 3px 5px rgba($black, .30);
        transition: all .2s;
        &:hover {
            color: $white;
            background: $secondary;
        }
    }
}

.x-shift-table-wrapper {
    margin-bottom: 1.5rem;
    display: inline-block;
    min-width: 100%;
    padding-right: 20px;

    .-header-wrapper,
    .-detail-wrapper {
        display: flex;
        align-items: center;
        &.dragging {
          border: none;
          background-color: $secondary;
          .-time-outer-wrapper {
            color: $white;
          }
        }
    }

    .-header-wrapper {
        font-weight: $font-weight-bold;
    }

    .-detail-wrapper {
        min-height: 85px;
    }

    .-detail-outer-wrapper {
        border: 1px solid #bdc5ce;
        border-radius: 10px;
        .-detail-wrapper {
            &:not(:first-child) {
                border-top: 1px solid #bdc5ce;
            }
        }
    }

    .-time-outer-wrapper {
        min-width: 160px;
        text-align: center;
        color: $black;
        .-time-value {
            margin-left: 1rem;
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
        }
        .-time-btn {
            border: none;
            background: transparent;
            outline: none;
        }
    }

    .-date-outer-wrapper {
        flex: 1;
        display: flex;
        text-align: center;
        .-date-item-wrapper {
            position: relative;
            flex: 1;
            padding: 10px;
            a {
                height: 100%;
            }
        }
    }

    .-profile-wrapper {
        position: relative;
        max-width: 100px;
        margin: auto;
    }

    .-profile-link-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
    }

    .-profile-ic-wrapper {
        position: relative;
        width: calc(100% / 3);
        img {
            width: 100%;
            height: auto;
            border: 1px solid #7c7c7c;
            border-radius: 50%;
        }
    }

    .-more-member-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: -8px;
        width: 16px;
        height: 16px;
        padding: 0;
        font-size: 10px;
        background: $primary;
        border-radius: 50%;
        transition: all .3s;
        .fa-plus {
            color: $white;
        }
        &:hover {
            background: lighten($primary, 10%);
        }
    }
}
