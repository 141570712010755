.x-crm-treatment-plan {
  .create-form {
    border-left: 1px solid $gray-200;
    height: 100%;
    .patient-label-container {
      background-color: $gray-200;
      .patient-row {
        &__label {
          width: 80px;
          display: inline-block;
        }
      }
    }
    &__row {
      margin-bottom: 1rem;
    }
    &__label {
      margin-bottom: 0.5rem;
    }
  }
}

.x-treatment-plan-state-button {
  min-width: 90px;
  margin-right: 5px;
  padding: 1px;
  font-size: 0.75rem;
  border: none;
  border-radius: 5px;
  &.-condition {
    background: #000000;
  }
  &.-planned {
    background: #ff5851;
  }
  &.-existing {
    background: #00c0c9;
  }
  &.-completed {
    background: #2542f2;
  }
}

.x-treatment-plan-state-dot {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 5px;
  &.-condition {
    background: #000000;
  }
  &.-planned {
    background: #ff5851;
  }
  &.-existing {
    background: #00c0c9;
  }
  &.-completed {
    background: #2542f2;
  }
}
