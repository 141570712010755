.x-qr-code-item-container {
    display: flex;

    .-img-wrapper {
        flex: 0 0 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 150px;
        background: #f5f5f6;
        .-img {
            width: 100%;
            height: auto;
        }
    }

    .-detail-wrapper {
        flex: 1 1 auto;
        position: relative;
        padding: 1rem 1rem 2rem;
        background: #f5f5f6;
    }

    .-option-btn {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
