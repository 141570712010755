.x-employee-group-card {
    width: 100%;
    height: 100%;
    padding: 1.25rem 1rem;
    font-size: $font-size-big;
    text-align: center;
    color: $white;
    border-radius: 8px;

    .fas {
        font-size: $font-size-biggest;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.x-hr-employee-group-index-container {
    .-box {
        position: relative;
        display: block;
        height: 100%;
    }
}

.x-hr-employee-group-management-container {
    .-edit-name {
        width: auto;
    }
    .-card-container {
        flex: 1;
        max-width: calc(50% - 2px);
    }
}
