.consent-modal .modal-content {
  border-radius: 10px;
  border-width: 0;
  overflow: hidden;
}

.consent-modal-title {
  text-align: center;
  padding: 15px 0;
}

.consent-modal-container {
  padding: 15px;
  .consent-text {
    text-indent: 20px;
  }
}

.consent-modal-header-line {
  height: 10px;
  background-color: $primary;
}

.consent-modal-footer {
  display: flex;
  justify-content: space-between;
}

.consent-checkbox-label {
  padding-left: 5px;
  font-weight: bold;
}

.consent-remark {
  padding-left: 28px;
}

.consent-checkbox-container label {
  align-items: center;
  margin: 0;
}

.modal-85w {
  min-width: 85vw;
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    font-size: 25px;
    cursor: pointer;
  }

  .consent-modal-header-line {
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    color: white;
    font-size: 20px;
  }
}

.link-style {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}