.slick-slider {
    margin-left: 0;
    margin-right: 0;
}

.x-section-heading-title {
    font-size: $font-size-base * 1.75;
    color: $primary;

    &.-small {
        font-size: $font-size-bigger;
    }

    &.-smaller {
        font-size: $font-size-big;
    }

    .-sub-title {
        margin-left: 10px;
        font-size: .875em;
        font-weight: $font-weight-normal;
        color: $secondary;
    }
}

.x-font {
    &-smaller {
        font-size: $font-size-smaller;
    }
    &-small {
        font-size: $font-size-small;
    }
    &-normal {
        font-size: $font-size-normal;
    }
    &-normal-to-big {
        font-size: $font-size-normal-to-big;
    }
    &-big {
        font-size: $font-size-big;
    }
    &-bigger {
        font-size: $font-size-bigger;
    }
    &-biggest {
        font-size: $font-size-biggest;
    }
}

.popover {
    max-width: 500px;
    font-family: 'Mitr' !important;
}

.react-datepicker__month-text {
    padding-top: 5px;
    padding-bottom: 5px;
    &.react-datepicker__month--selected {
        background: $secondary;
        &:hover {
            background-color: $secondary;
        }
    }
}

.x-form-no-border {
    border: none !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-radius: 0 !important;
}

.x-remove-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $white;
    .fas {
        font-size: 20px;
        position: absolute;
        top: -1px;
        left: 2px;
        color: $secondary;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.x-table {
    td {
        padding: .5rem;
    }
}

.x-table-text-wrap {
    td {
        white-space: normal;
        overflow: hidden;
    }
}

.-package-modal {
    .table-responsive {
        min-height: 0;
    }
}

.-table-responsive-no-height {
    .table-responsive {
        min-height: 0;
    }
}

.x-btn-choose-file {
    position: relative;
    overflow: hidden;
    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
    }
}

.x-modal-overlay {
    filter: blur(2px) brightness(.35);
}
