.x-modal {
    &.-primary {
        .modal-header {
            color: $white;
            background: $primary;
        }
    }

    &.-transparent {
        .modal-header {
            color: $primary;
            background: transparent;
            border-bottom: none;
        }
        .close {
            color: $secondary !important;
        }
    }
}
