.x-teeth-selection-piece-wrapper,
.x-teeth-selection-piece-show-wrapper {
    .-upper-wrapper,
    .-bottom-wrapper {
        display: flex;
        align-items: flex-end;
    }

    .-teeth-item-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px;
        background: none;
        border: none;
        border-radius: 0;
        transition: all 0.2s;
        &:hover {
            background: none;
            border: none;
            .-img {
                filter: drop-shadow(0 0 0 rgba($black, 0.1));
            }
        }
        &.-active {
            .-btn,
            .-btn-sm {
                color: $white;
                background: $primary;
            }
        }
        &.-active-latest {
            background-color: #ccf2fc;

            .-btn,
            .-btn-sm {
                color: $white;
                background: $primary;
            }
        }
        &:not(.-active) {
            &:hover {
                .-btn {
                    background: rgba($primary, 0.25);
                }
            }
        }
        &:not(:disabled) {
            &:active {
                background: none;
                border: none;
            }
        }
        .-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            font-size: 0.55rem;
            line-height: 1.2;
            color: #a8a6ac;
            background: #ebebec;
            border-color: #ebebec;
            border-radius: 50%;
            @media (max-width: 1200px) {
                width: 16px;
                height: 16px;
            }
        }

        &.teeth-item-wrapper-sm {
            padding: 2px !important;
        }

        .-btn-sm {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            font-size: 0.4rem;
            line-height: 1.2;
            color: #a8a6ac;
            background: #ebebec;
            border-color: #ebebec;
            border-radius: 50%;
            @media (max-width: 1200px) {
                width: 12px;
                height: 12px;
            }
        }
    }

    .-img-container {
        position: relative;
    }

    .-img {
        width: 100%;
        height: auto;
        &.-type {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
        }
        &.-active {
            &.-type {
                opacity: 1;
                visibility: visible;
            }
        }

        &.-cdt-filter {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .-bottom-wrapper {
        margin-top: 1rem;
        .-teeth-item-wrapper {
            .-img-container {
                order: 1;
            }
        }
    }

    .-control-panel-wrapper {
        max-width: 380px;
        margin: auto;

        &.is-plan {
            max-width: 500px;
        }

        .-control-panel-top-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .btn {
            padding: 0.75rem 0.5rem;
            font-size: 0.75rem;
            color: #a8a6ac;
            background: #ebebec;
            border-color: #ebebec;
            &.-active {
                color: $white;
                background: $primary;
            }
            &:not(.-active) {
                &:hover {
                    color: $white;
                    background: $primary;
                }
            }
        }
        .-zone-selection {
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn {
                width: 90px;
                margin: 0.25rem;

                &.is-plan {
                    width: 80px;
                    margin: 0.25rem;
                    font-size: 10px !important;
                }
            }

            .btn-sm-layout {
                width: 25px;
                margin: 2px;
                padding: 5px;
                font-size: 8px !important;
            }
        }
        .-type-selection {
            text-align: center;
            .-type-selection-top-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            // .-type-selection-bottom-wrapper {
            //     margin-top: 1rem;
            // }
            .btn {
                margin: 0.25rem;
                width: 44px;

                &.is-plan {
                    width: 35px;
                    font-size: 10px !important;
                }
            }

            .btn-sm-layout {
                margin: 2px;
                width: 25px;
                padding: 5px;
                font-size: 8px !important;
            }
        }
        .-state-selection {
            text-align: center;
            // width: 100px;
            margin-left: 50px;

            .row {
                width: 100px;
            }

            .col-6 {
                padding: 0;
            }

            .btn {
                width: 30px;
                height: 30px;
            }
        }
        .-volume-selection {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            .btn {
                width: 120px;
            }

            .btn-sm-layout {
                margin: 0.25rem;
                width: 70px;
                padding: 5px;
                font-size: 10px !important;
            }
        }
    }

    .teethchart-state-info {
        font-size: 11px;
        flex-wrap: wrap;
    }
}

.x-teeth-selection-piece-show-wrapper {
    .-show-wrapper {
        //width: 60%;
        text-align: center;
        .-img-container {
            width: 50px;
            margin: auto;
        }
        &.-has-area {
            flex-direction: row;
            // padding-right: 2rem;
            // .-img-container {
            //     width: 100%;
            // }
        }
        &.-is-full-mouth {
            flex-wrap: wrap;
            .-teeth-col-wrapper {
                width: calc(100% / 16);
                flex: unset;
            }
        }
    }
    .-control-wrapper {
    }
    .-control-panel-wrapper .-type-selection .btn {
        width: 38px;
        padding: 0.5rem;
    }

    .-teeth-col-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }
}

.plan-teeth-area-selected {
    padding: 0 10px 5px 10px;
    border-radius: 5px;
    background-color: #ffffff;
    // background-color: #f2f2f2;
    border: 1px solid #e5e5e5;
    flex-flow: wrap;

    &.is-disabled {
        background-color: #f2f2f2;
    }

    .teeth-area-selected-item {
        margin-top: 5px;
        background-color: #dcdbdb;
        padding: 5px;
        margin-right: 5px;
        border-radius: 3px;
    }
}
